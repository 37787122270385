<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/site-pages">
            <button class="btn btn-primary">
              {{ $t('back') }}
              <i class="mdi mdi-keyboard-backspace"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 page-loader-parent">
                <div class="page-loader">
                  <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
            </div>
            <form id="add-page-form" class="needs-validation" @submit.prevent="tooltipForm">
              <div class="row">
                <div class="col-md-12">
                  <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">
                    <b-tab active>
                      <template v-slot:title>
                        <span class="d-none d-sm-inline-block">عربي</span>
                      </template>
                      <div class="col-md-12">
                        <div class="mb-4 mt-3 position-relative">
                          <label class="form-label" for="validationTooltip01">عنوان الصفحة</label>
                          <input id="validationTooltip01" v-model="tooltipform.ar_page_name" type="text"
                            class="form-control" placeholder="عنوان الصفحة" :class="{
                              'is-invalid': submitform && $v.tooltipform.ar_page_name.$error,
                            }" />
                          <div v-if="submitform && $v.tooltipform.ar_page_name.$error" class="invalid-tooltip">
                            <span v-if="!$v.tooltipform.ar_page_name.required">
                              عنوان الصفحة مطلوب.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label class="form-label" for="validationTooltip01">محتوى الصفحة</label>
                        <ckeditor v-model="tooltipform.arPageContent" :editor="editor"></ckeditor>
                        <div v-if="submitform && $v.tooltipform.arPageContent.$error" class="invalid-tooltip">
                          <span v-if="!$v.tooltipform.arPageContent.required">
                            محتوى الصفحة مطلوب.
                          </span>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-none d-sm-inline-block">English</span>
                      </template>
                      <div class="col-md-12">
                        <div class="mb-4 mt-3 position-relative">
                          <label class="form-label" for="validationTooltip01">عنوان الصفحة</label>
                          <input id="validationTooltip01" v-model="tooltipform.en_page_name" type="text"
                            class="form-control" placeholder="عنوان الصفحة" :class="{
                              'is-invalid': submitform && $v.tooltipform.en_page_name.$error,
                            }" />
                          <div v-if="submitform && $v.tooltipform.en_page_name.$error" class="invalid-tooltip">
                            <span v-if="!$v.tooltipform.en_page_name.required">
                              عنوان الصفحة مطلوب.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label class="form-label" for="validationTooltip01">محتوى الصفحة</label>
                        <ckeditor v-model="tooltipform.enPageContent" :editor="editor"></ckeditor>
                        <div v-if="submitform && $v.tooltipform.enPageContent.$error" class="invalid-tooltip">
                          <span v-if="!$v.tooltipform.enPageContent.required">
                            محتوى الصفحة مطلوب.
                          </span>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
              <button class="btn btn-primary float-right m-3" type="submit">
                {{ $t('save') }}
                <i class="mdi mdi-content-save-outline"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
var that;
import $ from 'jquery';
import Layout from "../../layouts/main";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import appConfig from "@/app.config";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "page modification",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, ckeditor: CKEditor.component, },
  data() {
    return {
      title: "page modification",
      tooltipform: {
        ar_page_name: "",
        en_page_name: "",
        arPageContent: "",
        enPageContent: "",
      },
      editor: ClassicEditor,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    tooltipform: {
      ar_page_name: { required },
      en_page_name: { required },
      arPageContent: { required },
      enPageContent: { required },
    },
  },
  methods: {
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    Jquery() {
      $(document).on('submit', '#add-page-form', function () {
        if (!that.$v.$anyError) {
          $(this).find(":submit").attr('disabled', 'disabled');
          let form = new FormData();
          form.append('en_content', that.tooltipform.enPageContent);
          form.append('ar_content', that.tooltipform.arPageContent);
          form.append('ar_page_name', that.tooltipform.ar_page_name);
          form.append('en_page_name', that.tooltipform.en_page_name);
          form.append('lang', that.$i18n.locale);
          const headers = {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          };
          var url = `${that.$appUrl}/api/admin/pages/update/${this.$route.params.id}`;
          that.axios.post(url, form, { headers })
            .then(response => {
              if (response.data.status == 1) {
                that.$toast.open({
                  message: response.data.message,
                  type: 'success',
                  position: 'top-left',
                  duration: 2500
                });
                setTimeout(() => {
                  that.$router.push(`/site-pages`);
                }, 1000);
              } else {
                $(this).find(":submit").prop('disabled', false);
                that.$toast.open({
                  message: response.data.message,
                  type: 'error',
                  position: 'top-left',
                  duration: 2500
                });
              }
            });
        }
      });
    }
  },
  mounted() {
    that = this;
    this.Jquery();
    $('.page-loader-parent').show();
    $('#add-page-form').hide();
    var url = `${this.$appUrl}/api/admin/pages/edit/${this.$route.params.id}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.tooltipform.ar_page_name = response.data.page.ar_page_name;
      this.tooltipform.en_page_name = response.data.page.en_page_name;
      this.tooltipform.arPageContent = response.data.page.ar_content;
      this.tooltipform.enPageContent = response.data.page.en_content;
      $('.page-loader-parent').remove();
      $('#add-page-form').show();
    });
  }
};
</script>
<style lang="scss" scoped>
input[type="number"],
input[type="date"],
input[type="text"] {
  border-color: rgba(120, 130, 140, .2);
  border-radius: 0;
  min-height: 2.375rem;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.input-group-text {
  border-radius: 0 !important;
  min-height: 2.375rem;
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.table-responsive:has(.page-loader-parent) {
  min-height: 350px;
}
</style>